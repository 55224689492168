// Migrated
<template lang="pug">
.d-print-none.container.px-0
  PrettyTabsNav.pretty-tabs-layout-column.pretty-tabs-layout-md-row(
    is-sol
    :tabs="navTabs" 
    :locale-urls="tabsNavLocaleUrls"
    @on-tab-change="onTabChange"
  )
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  emits: [
    'onDestinationTabChange',
    'onHotelTabChange',
  ],

  computed: {
    ...mapState(useCharterDestinationStore, {
      destination: 'charterDestination',
      destinationTitle: 'charterDestinationTitle',
      destinationTabs: 'charterDestinationTabs',
      destinationPath: 'charterDestinationPath',
    }),

    ...mapState(useCharterHotelStore, {
      hotel: 'charterHotel',
      hotelTab: 'charterHotelTab',
      hotelPath: 'charterHotelPath',
    }),

    ...mapState(useLocaleStore, [
      'localeURLs',
    ]),

    ...mapState(useDealfinderStore, {
      hotelInfo: 'hotel',
    }),

    isHotel () {
      return this.$route.name?.startsWith('hotel-id')
    },

    isDestination () {
      return this.$route.name?.startsWith('destination-id')
    },

    tabsNavLocaleUrls () {
      return this.localeURLs[this.isDestination ? 'destinationsId' : 'hotelId']
    },

    navTabs () {
      if (this.isDestination) {
        const destinationLocaleUrls = this.localeURLs.destinationsId

        const tabs = [
          {
            text: 'destinationTabInfo',
            to: this.destinationPath,
            localeUrlKey: 'info',
            translationKey: 'destinationTabInfo',
          },
        ]

        const sections  = this.destinationTabs

        if (sections.includes('hotel_list')) {
          tabs.push({
            text: 'destinationTabHotel',
            to: `${this.destinationPath}${destinationLocaleUrls.hotel}`,
            localeUrlKey: 'hotel',
            translationKey: 'destinationTabHotel',
          })
        }

        if (sections.includes('areas_of_interest')) {
          tabs.push({
            text: 'destinationTabArea',
            to: `${this.destinationPath}${destinationLocaleUrls.area}`,
            localeUrlKey: 'area',
            translationKey: 'destinationTabArea',
          })
        }

        if (sections.includes('services')) {
          tabs.push({
            text: 'destinationTabServices',
            to: `${this.destinationPath}${destinationLocaleUrls.services}`,
            localeUrlKey: 'services',
            translationKey: 'destinationTabServices',
          })
        }

        if (sections.includes('beaches')) {
          tabs.push({
            text: 'destinationTabBeaches',
            to: `${this.destinationPath}${destinationLocaleUrls.beaches}`,
            localeUrlKey: 'beaches',
            translationKey: 'destinationTabBeaches',
          })
        }

        if (this.destination.tui_musement && sections.includes('activities')) {
          tabs.push({
            text: 'destinationTabActivities',
            to: `${this.destinationPath}${destinationLocaleUrls.activities}`,
            localeUrlKey: 'activities',
            translationKey: 'destinationTabActivities',
          })
        }

        if (sections.includes('weather')) {
          tabs.push({
            text: 'destinationTabWeather',
            to: `${this.destinationPath}${destinationLocaleUrls.weather}`,
            localeUrlKey: 'weather',
            translationKey: 'destinationTabWeather',
          })
        }

        return tabs
      }

      if (this.isHotel) {
        const hotelPath = `${this.localeURLs.hotel}/${this.$route.params.id}`
        const tabs = [
          {
            text: 'hotelTabInfo',
            to: hotelPath,
            localeUrlKey: 'info',
            translationKey: 'hotelTabInfo',
          },
          {
            text: 'facilitiesTab',
            to: `${hotelPath}${this.localeURLs.hotelId.facilities}`,
            localeUrlKey: 'facilities',
            translationKey: 'facilitiesTab',
          },
          //To be added in future
          /*  {
            text: 'roomTypeTab',
            to: `${hotelPath}${this.localeURLs.hotelId.roomType}`,
            localeUrlKey: 'roomType',
            translationKey: 'roomTypeTab'
          }, */
          /* {
            text: 'foodAndDrinkTab',
            to: `${hotelPath}${this.localeURLs.hotelId.food}`,
            localeUrlKey: 'food',
            translationKey: 'foodAndDrinkTab'
          },
          {
            text: 'reviewsTab',
            to: `${hotelPath}${this.localeURLs.hotelId.reviews}`,
            localeUrlKey: 'reviews',
            translationKey: 'reviewsTab'
          }, */
        ]

        if (this.hotelInfo?.destination?.tui_musement) {
          tabs.push({
            text: 'excursionsTab',
            to: `${hotelPath}${this.localeURLs.hotelId.activities}`,
            localeUrlKey: 'activities',
            translationKey: 'excursionsTab',
          })
        }
        return tabs
      }
    },
  },

  methods: {
    onTabChange (newActiveTab) {
      if (this.isHotel) {
        this.$emit('onHotelTabChange', newActiveTab)
      } else if (this.isDestination) {
        this.$emit('onDestinationTabChange', newActiveTab)
      }
    },
  },
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/nav-tabs";
.nav-item.favourite--tab{
  .favourite-icon {
    background: transparent !important;
  }
}
</style>
